<template>
  <div class="table-components">
    <div class="table-title">
      <img src="@/assets/imgs/t7.png" alt="" />
      <span class="s-1">历史网络足迹</span>
      <span class="s-2">
        共有
        <span class="s-2-1">{{ tableList.length }}</span>
        条，
        <span v-if="!socketLoading">查询已完成</span>
        <span v-else>查询进行中...</span>
      </span>
    </div>
    <el-table header-row-class-name="tableComheader" :data="tableData">
      <el-table-column label="主页链接">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.index_url"
            placement="bottom"
            effect="light"
          >
            <span
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              <!-- {{ scope.row.password }} -->
              <el-link
                type="primary"
                @click="handleTitleClick(scope.row.index_url)"
              >
                {{ scope.row.index_url }}
              </el-link>
            </span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column prop="website_name" label="来源网站"></el-table-column>
      <el-table-column prop="website_logo" label="网站logo"></el-table-column>
    </el-table>
    <pagination
      :total="tableList.length"
      :page-size="limit"
      :currentPage="current"
      :pagerCount="5"
      :background="false"
      :sizesArr="[10, 20, 50, 100]"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    tableList: {
      type: Array,
      default: []
    },
    socketLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tableData() {
      let arr = []
      if (this.tableList.length > 0) {
        arr = this.tableList.slice(
          (this.current - 1) * this.limit,
          this.current * this.limit
        )
      }
      return arr
    }
  },
  data() {
    return {
      current: 1,
      limit: 10
    }
  },
  methods: {
    handleTitleClick(data) {
      window.open(data, '_blank')
    },
    currentChange(val) {
      this.current = val
    },

    sizeChange(val) {
      this.current = 1
      this.limit = val
    }
  }
}
</script>

<style lang="scss" scoped>
.table-components {
  margin-bottom: 20px;
  border: 1px solid #d8d9d9;
  .table-title {
    width: 100%;
    height: 48px;
    background: white;
    box-shadow: 0px 2px 7px #d8d9d9;
    margin-bottom: 7px;
    line-height: 48px;
    white-space: nowrap;

    img {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-left: 15px;
    }
    .s-1 {
      font-size: 16px;
      margin-left: 15px;
      font-weight: 400;
      color: #355383;
    }
    .s-2 {
      font-size: 12px;
      margin-left: 40px;
      font-weight: 400;
      color: #355383;
      .s-2-1 {
        font-size: 20px;

        font-weight: 600;
        color: #409eff;
      }
    }
  }

  ::v-deep .tableComheader th {
    background: rgb(243, 247, 249);
    padding: 8px 0;
  }
}
</style>
