let displayName = {
  //   website_id: '网站id',

  //   website_name: '来源',

  //   website_category: '网站分类',

  //   website_desc: '网站简介',

  //   website_logo: '网站logo',

  //   search_uid: '搜索id',

  //   search_time: '搜索时间',

  //   regist: '是否注册',

  phone: '手机号',

  hide_phone: '隐藏手机',

  email: '邮箱',

  hide_email: '隐藏邮箱',

  username: '用户名',

  hide_username: '隐藏用户名',
  nickname: '昵称',
  country: '国家',

  id_card: '身份证号码',
  password: '密码',
  zipcode: '邮编',

  bank_card: '银行卡',

  hide_bank_card: '隐藏银行卡',
  ip: 'ip地址',
  userid: '用户数字id',

  modify_time: '变更时间',

  last_login_time: '最后登录时间',

  index_url: '用户主页',

  regist_time: '注册时间',
  address: '地址',
  birth: '生日',
  gender: '性别',
  constellation: '星座',

  //   avatar_url: '头像链接',
  level: '帐号等级',
  description: '个人简介',

  follow_count: '关注数',

  fans_count: '粉丝数',

  publish_count: '发表数',
  tag: '标签',
  educate: '学历',
  school: '学校',
  work: '工作信息',

  real_name: '真实姓名',
  company: '公司',

  didi_info: '滴滴模,块是否已经实名',

  kuaishou_multi_users: '快手多用户',

  expand_info: '拓展信息'
}

module.exports = {
  displayName
}
